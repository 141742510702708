@use "../../../styles/styles" as *;

.tabs {
    position: absolute;
    bottom: 0;
    padding-left: 1rem;
    padding-right: 1rem;
    z-index: 0;
    // overflow: hidden;

    &__container {
        position: relative;
        width: 100%;
        height: 0;
        display: flex;
        justify-content: right;
        padding-right: 4rem;
        column-gap: 2rem;
    }

    &__tab {
        width: 12rem;
        background-color: $PORTRED;
        @include boxshadow;

        &--work {
            margin-right: 2rem;
        }

        &--selected {
            background-color: $PORTYELL;
        }
    }
}
