@use "./styles/styles" as *;

.body {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    background-color: $PORTBLUE;
    z-index: -10;
}

.container {
    max-width: 128rem;
    width: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.menu__container {
    min-width: 50%;
    padding: 8rem 4rem 0 4rem;
    position: relative;
    overflow: hidden;
}

.details__container {
    min-width: 50%;
    padding: 0rem 4rem 12rem 4rem;
    position: relative;
}
