@use "./variables" as *;

button {
    appearance: none;
    background: none;
    border: none;
    padding: 0;
    margin: 0;
}

body {
    margin: 0;
    background-color: $PORTBLUE;
}

ul {
    appearance: none;
}

li {
    appearance: none;
}
