@mixin boxshadow {
    box-shadow: 0px 0.4rem 0.4rem 0.4rem rgba(0, 0, 0, 0.5);
}

@mixin pathshadow {
    filter: drop-shadow(0.4rem 0.4rem 0.4rem rgba(0, 0, 0, 0.5));
}

@mixin textshadow {
    text-shadow: (0px 0.4rem 0.4rem rgba(0, 0, 0, 0.25));
}
