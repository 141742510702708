@use "../../styles/styles" as *;

.menu {
    max-width: 56rem;
    background-color: $PORTTAN;
    height: 100%;
    width: 100%;
    padding: 4rem;
    @include boxshadow;
    position: absolute;
}

.headline {
    &__name {
        margin-top: 0;
        margin-bottom: 12rem;
    }
}
