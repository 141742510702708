@font-face {
    font-family: "Sans";
    src: url("../../assets/fonts/JosefinSans.woff") format("woff");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Slab Medium";
    src: url("../../assets/fonts/JosefinSlab-Medium.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Slab SemiBold";
    src: url("../../assets/fonts/JosefinSlab-SemiBold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
