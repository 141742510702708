@use "../../../styles/styles" as *;

.tech {
    position: absolute;
    bottom: 0;
    left: 0rem;

    &__title {
        writing-mode: vertical-rl;
        margin: 0.5rem;
    }

    &__button {
        background-color: $PORTRED;
        padding: 2rem 0;
    }

    &__box {
        min-width: 4rem;
        max-width: 4rem;
        min-height: 4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin: 0 1rem 1rem 0;

        &--wide {
            max-width: none;
        }
    }
    &__icon {
        max-height: 4rem;
    }

    &__drawer {
        display: flex;
        background-color: $PORTTAN;
        transform: translateX(-43rem);
        transition: 1s;
        min-width: 45rem;
        max-width: 45rem;
        @include boxshadow;

        &--active {
            transform: translateX(-1rem);
            transition: 1s;
        }
    }

    &__container {
        padding: 1rem;
        width: 47rem;
        // overflow: hidden;
        height: 25rem;

        &--active {
        }
    }

    &__list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin: 2rem 4rem;
        padding: 1rem;
        background-color: $PORTEGG;
        list-style-type: none;
    }
}
