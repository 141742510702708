@use "../../../styles/styles" as *;

.brainstation-frame {
    padding: 1rem;
    border: solid 1rem $PORTEGG;
    display: flex;
    flex-direction: column;
    width: 24rem;
    align-items: center;
    background-color: $PORTEGG;
    @include boxshadow;

    &__image {
        width: 20rem;
    }
    &__text {
        margin: 1rem 0 0;
        color: $PORTBLUE;
    }
}

.details {
    width: 100%;
    background-color: $PORTTAN;
    min-height: 81rem;
    padding: 1rem;
    position: relative;
    padding: 4rem;
    background: linear-gradient(315deg, rgba(234, 226, 183, 1) 50%, rgba(247, 127, 0, 1) 50.01%);
    overflow: hidden;
    z-index: 10;
    @include boxshadow;
    height: 100%;

    &__frame {
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 4rem;
    }

    &__screenshot {
        width: 30rem;
        border: 1rem solid $PORTEGG;
        object-fit: cover;
        @include boxshadow;
        display: table-cell;

        &--home {
            float: left;
            margin: 0 2rem 1rem 0;
        }

        &--use {
            margin-top: 9rem;
            border: 1rem solid $PORTYELL;
        }
    }

    &__alignment {
        width: 100%;
        float: right;
        display: flex;
        justify-content: right;
        shape-outside: polygon(
            100% 30%,
            calc(100% - 31rem) 30%,
            calc(100% - 31rem) 100%,
            100% 100%,
            100% 0%
        );
    }

    &__summary {
        font-size: 2.4rem;
        color: $PORTBLUE;

        &--nerf {
            font-size: 3rem;
            font-family: "Slab Medium";
        }
    }

    &__content {
        display: table;
        width: 100%;
    }
}

.nerf-herder-logo {
    fill: #78cce2;
    width: 20rem;
    padding: 2rem;
    filter: drop-shadow(0 0 0.1rem #78cce2);

    &__container {
        background: rgba(0, 80, 102, 0.5);
        border: 0.5rem solid #78cce2;
        box-shadow: 0 0 0.5rem #78cce2, inset 0 0 0.3rem #78cce2;
        @include pathshadow;
    }
}
